export const menuItemsAdminAward = [

  //onglet pour l'ADMINISTRATEUR DE COMPETITON
  //====================================================

  {
    id: 6,
    icon: "mdi mdi-trophy-outline",
    label: "sidebar.award.title",
    link: "/award/awardList",
    role: ["ROLE_AWARD"],
  },

  //=================================================================
];
