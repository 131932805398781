export const menuCreator = [
  // onglet pour le CREATEUR DE CONTENU
  //===================================================
  {
    id: 7,
    icon: "mdi mdi-text-box-multiple-outline",
    label: "sidebar.article.title",
    subItems: [
      {
        id: 7001,
        label: "sidebar.article.articleList",
        link: "/blog/list",
        parentId: 7,
      },
      {
        id: 7002,
        label: "sidebar.article.tag",
        link: "/blog/tagsArticle",
        parentId: 7,
      },
      {
        id: 7003,
        label: "sidebar.article.categories",
        link: "/blog/categArticle",
        parentId: 7,
      },
    ],
  },

  {
    id: 2,
    label: "sidebar.content.title",
    icon: "bx-grid-alt",
    subItems: [
      {
        id: 2001,
        label: "sidebar.content.videos",
        link: "/videos",
        parentId: 2,
      },
      {
        id: 2002,
        label: "sidebar.content.programs",
        link: "/programmes",
        parentId: 2,
      },
      {
        id: 2004,
        label: "sidebar.content.tag",
        link: "/tags",
        parentId: 2,
      },
      {
        id: 2004,
        label: "sidebar.content.categorie",
        link: "/categories",
        parentId: 2,
      },
    ],
  },

  {
    id: 3,
    label: "sidebar.planification.title",
    icon: "bx bx-calendar",
    subItems: [
      // {
      //   id: 3001,
      //   label: "sidebar.planification.program",
      //   link: "/projects/programmes",
      //   parentId: 3,
      // },
      {
        id: 3004,
        label: "sidebar.planification.showPlanning",
        link: "/calendar",
        parentId: 3,
      },
    ],
  },
  //=================================================================
];
