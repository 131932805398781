export const adminMenuItems = [
  // onglets pour l'ADMINISTRATEUR
  //==============================================================
  {
    id: 1,
    icon: "bx-bar-chart",
    label: "sidebar.dashboard.title",
    link: "/dashboard",
  },
  {
    id: 6,
    icon: "mdi mdi-trophy-outline",
    label: "sidebar.award.title",
    link: "/award/awardList",
  },
  {
    id: 8,
    icon: "mdi mdi-bullhorn-outline",
    label: "sidebar.pub.title",
    subItems: [
      {
        id: 8001,
        label: "sidebar.pub.lite",
        link: "/campagnes/displayLite",
        parentId: 8,
      },
    ],
  },
  {
    id: 9,
    icon: "mdi mdi-gamepad ",
    label: "sidebar.games.title",
    subItems: [
      {
        id: 9001,
        label: "sidebar.games.quiz",
        link: "/games/quizCategorie",
        parentId: 9,
      },
    ],
  },
  {
    id: 7,
    icon: "mdi mdi-text-box-multiple-outline",
    label: "sidebar.article.title",
    subItems: [
      {
        id: 7001,
        label: "sidebar.article.articleList",
        link: "/blog/list",
        parentId: 7,
      },
      {
        id: 7002,
        label: "sidebar.article.tag",
        link: "/blog/tagsArticle",
        parentId: 7,
      },
      {
        id: 7003,
        label: "sidebar.article.categories",
        link: "/blog/categArticle",
        parentId: 7,
      },
    ],
  },
  {
    id: 2,
    label: "sidebar.content.title",
    icon: "bx-grid-alt",
    subItems: [
      {
        id: 2001,
        label: "sidebar.content.videos",
        link: "/videos",
        parentId: 2,
      },
      {
        id: 2002,
        label: "sidebar.content.programs",
        link: "/programmes",
        parentId: 2,
      },
      {
        id: 2,
        label: "sidebar.content.section.title",
        icon: "bx-grid-alt",
        subItems: [
          {
            id: 2001,
            label: "sidebar.content.section.videos",
            link: "/sections/sectionVideos",
            parentId: 2,
          },
          {
            id: 2002,
            label: "sidebar.content.section.programs",
            link: "/sections/sectionPrograms",
            parentId: 2,
          },
          {
            id: 2003,
            label: "sidebar.content.section.images",
            link: "/sections/sectionImages",
            parentId: 2,
          },
        ],
      },
      // {
      //   id: 2003,
      //   label: "sidebar.content.section.title",
      //   link: "/sections",
      //   parentId: 2,
      // },
      {
        id: 2004,
        label: "sidebar.content.tag",
        link: "/tags",
        parentId: 2,
      },
      {
        id: 2004,
        label: "sidebar.content.categorie",
        link: "/categories",
        parentId: 2,
      },
    ],
  },
  // {
  //   id: 2,
  //   label: "sidebar.content.title",
  //   icon: "bx bx-edit",
  //   subItems: [
  //     {
  //       id: 2001,
  //       label: "sidebar.content.publish",
  //       link: "/projects/videos",
  //       parentId: 2,
  //     },
  //     {
  //       id: 2003,
  //       label: "sidebar.content.videos",
  //       link: "/projects/categories",
  //       parentId: 2,
  //     },
  //   ],
  // },
  {
    id: 3,
    label: "sidebar.planification.title",
    icon: "bx bx-calendar",
    subItems: [
      // {
      //   id: 3001,
      //   label: "sidebar.planification.program",
      //   link: "/projects/programmes",
      //   parentId: 3,
      // },
      {
        id: 3004,
        label: "sidebar.planification.showPlanning",
        link: "/calendar",
        parentId: 3,
      },
    ],
  },
  // {
  //   id: 4,
  //   label: "sidebar.section.title",
  //   icon: "bx-grid-alt",
  //   subItems: [
  //     {
  //       id: 4001,
  //       label: "sidebar.section.section",
  //       link: "/projects/sections",
  //       parentId: 2,
  //     },
  //     {
  //       id: 4002,
  //       label: "sidebar.section.sectionProgram",
  //       link: "/projects/sectionProgramme",
  //       parentId: 2,
  //     },
  //   ],
  // },
  {
    id: 5,
    icon: "bx-cog",
    label: "sidebar.settings.title",
    subItems: [
      {
        id: 5001,
        label: "sidebar.help.title",
        parentId: 6,
        subItems: [
          {
            id: 5001001,
            label: "sidebar.help.slider",
            link: "/projects/slide",
            parentId: 5001,
          },
          {
            id: 5001002,
            label: "sidebar.help.screenVue",
            link: "/projects/images",
            parentId: 5001,
          },
          // {
          //   id: 5001003,
          //   label: "sidebar.help.tags",
          //   link: "/projects/tags",
          //   parentId: 5001,
          // },
          {
            id: 5001004,
            label: "sidebar.help.link",
            link: "/projects/link",
            parentId: 5001,
          },
        ],
      },
      {
        id: 5002,
        label: "sidebar.channel.title",
        subItems: [
          {
            id: 5002001,
            label: "sidebar.channel.channel",
            link: "/projects/channels",
            parentId: 5002,
          },
          {
            id: 5002002,
            label: "sidebar.channel.liveFlux",
            link: "/projects/liveFlux",
            parentId: 5002,
          },
        ],
        parentId: 5002,
      },
      {
        id: 5003,
        label: "sidebar.staff.title",
        icon: "bx-group",
        subItems: [
          {
            id: 5003002,
            label: "sidebar.staff.members",
            link: "/contacts/list",
            parentId: 5003,
          },
        ],
      },
    ],
  },
  //=================================================================
];
